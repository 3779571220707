.radioTileGroup {
    display: flex;
    flex-direction: column;
    gap: var(--space-8);
}

.featureRadioTile {
    --navbar-height: 72px;
    --padding-inline: var(--space-24);
    --padding-block: var(--space-16);

    display: block;
    position: relative;
    block-size: var(--tube-height-lg);
    padding: var(--padding-block) var(--padding-inline);
    overflow: hidden;
    white-space: nowrap;
    background-color: var(--radio-tile-background, var(--radiotile-background-unselected-idle));
    border-radius: var(--border-radius-2xl);
    transition: all var(--animation-duration-350) var(--animation-ease-base);
    scroll-margin-top: var(--navbar-height);

    &:hover {
        --description-text-visibility: inline;
        --radio-tile-background: var(--radiotile-background-unselected-onhover);

        &:not(.checked) {
            cursor: pointer;
        }
    }

    &.checked {
        --radio-tile-background: var(--radiotile-background-selected-green);
        --feature-text-color: var(--display-onlight-secondary);
        --icon-color: var(--display-onlight-primary);
        --description-text-visibility: inline;
        --color: var(--display-onlight-secondary);
    }

    &:has(input:focus-visible) {
        box-shadow: var(--shadow-focus);
    }

    &:has(:active):not(.checked) {
        transform: var(--card-active-transform);
    }
}

.seaSalt.checked {
    --radio-tile-background: var(--radiotile-background-selected-sea-salt);
}
.green.checked {
    --radio-tile-background: var(--radiotile-background-selected-green);
}
.red.checked {
    --radio-tile-background: var(--radiotile-background-selected-red);
}
.yellow.checked {
    --radio-tile-background: var(--radiotile-background-selected-yellow);
}

.radioTileInner {
    display: flex;
    gap: var(--space-16);
    color: var(--color, var(--display-onlight-tertiary));
}

.iconContainer {
    display: flex;
    align-items: center;
    block-size: calc(1em * var(--line-height));
    color: var(--icon-color, var(--display-onlight-tertiary));
}

.radioBody {
    overflow: hidden;
    text-overflow: ellipsis;
}

.titleText {
    color: var(--display-onlight-primary);
}

.descriptionText {
    display: var(--description-text-visibility, none);
}

.radioTileImage {
    width: 105%;
    height: auto;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
}

.radioInput {
    position: absolute;
    block-size: 1px;
    inline-size: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.scene {
    inline-size: calc(100% + var(--padding-inline) * 2);
    margin-inline-start: calc(var(--padding-inline) * -1);
    margin-inline-end: calc(var(--padding-inline) * -1);
    margin-block-end: calc(var(--padding-block) * -1);
    margin-block-end: calc(var(--space-24) * -1);
    margin-block-start: var(--space-24);
}

.sceneImage {
    inline-size: 100%;
    block-size: auto;
    max-inline-size: 590px;
    max-block-size: 550px;
}
